.App {
  text-align: center;
}

.sideByside {
  margin-right: 1%;
}

.vehicleDialogPadding {
  padding: 10px;
}

.boldText {
  font-weight: bold !important;
  padding-right: 2%;
}

.centered {
  height: 80vh; /* Magic here */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn {
  white-space: nowrap;
  text-align: center;
  border-radius: 16px !important;
}

table.native-table,
table.native-table tbody tr {
  border: 1px solid black;
  border-collapse: collapse;
}

.grid-right {
  display: flex;
  justify-content: flex-end;
}

.grid-left {
  display: flex;
  justify-content: flex-start;
}

.content-container {
  position: relative;
  min-height: 100vh;
}

.footer-pin {
  position: absolute;
  z-index: 0;
  min-height: 92px;
  width: 100%;
  bottom: calc(-5vh - 92px);
}

.unit-of-measurement {
  opacity: 0.6;
}

.vertical-print-bt {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}


.vertical-print-tb {
  writing-mode: vertical-rl;
}

.rotate-icon-1 {
  transform: rotate(270deg);
}

.rotate-icon-2 {
  /** flip and rotate an element */
  transform: rotate(270deg) scaleY(-1);
}

.rotate-icon-3 {
  /* vertically flip an element */
  transform: rotate(0deg) scaleY(-1);
}

.rotate-icon-4 {
  /* horizontally flip an element */
  transform: rotate(0deg) scaleX(-1);
}

.hiddenDetailIcon {
  display: none !important;
}

.leaflet-control-layers {
  /* contains the layer control pop-up */
  text-align: left;
}

.leaflet-container {
  /* contains the maps */
  height: 80vh;
  width: 100%;
}

.header-green {
  color: rgba(72, 169, 166);
}

.header-line {
  /* ensures the line in top right of page is black (defaults to white otherwise) */
  color: black !important;
}

.button-secondary-text {
  text-transform: lowercase;
  font-size: smaller;
}

.fleetinput-divider {
  width: 95%;
}

.chartdiv {
  position: relative;
}

.watermark-container {
  background-color: #ffffff80;
  position: absolute;
  bottom: 35px;
  right: 9px;
  padding: 6px;
  pointer-events: none;
}

.watermark-img {
  opacity: 0.6;
}

.excel-import-input {
  /* hides the "choose file" part of excel imports */
  display: none;
}

.textFieldBorder fieldset {
  border-radius: 25px;
}

.efficiency {
  margin-left: 10px;
  font-size: 3px;
}

.page-title.MuiTypography-root {
  /* styles the page's label header */
  font-weight: 600;
  margin-right: 1%;
}

.page-title2.MuiTypography-root {
  font-weight: 500;
  margin-right: 1%;
}

div:not(.has-value) > div:not(.Mui-focused) > input[type="time"] {
  /* makes the time input element invisible until entered/selected
  (for fleetManual page's Safari version) */
  color: #efeff0;
}

.only-print,
.only-print * {
  display: none;
}

/* Note: these are coming from  https://github.com/gilbarbara/react-joyride/blob/main/src/components/Beacon.js,
and are being used for the purposes of creating a custom joyride beacon animation*/
@keyframes joyride-beacon-inner-custom {
  20% {
    opacity: 0.9;
  }

  90% {
    opacity: 0.7;
  }
}

@keyframes joyride-beacon-outer-custom {
  0% {
    transform: scale(1);
  }

  45% {
    opacity: 0.7;
    transform: scale(0.75);
  }

  100% {
    opacity: 0.9;
    transform: scale(1);
  }
}
