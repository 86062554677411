/* todo: combine this with App.css, and then delete this file */
.table-heatmap {
  font-size: 13px;
  padding: 1px;
  cursor: default;
  border-collapse: collapse;
  /* Adjust the font size for month and hour labels */
}

.table-input,
.table-input th,
.table-input td {
  padding: 5px;
  font-size: 8px;
  border-collapse: collapse;
}
/* Adjust the font size for month and hour labels */

.table-heatmap th {
  font-weight: 500; /** remove bold */
}

.header-cell,
.data-cell {
  border: 1px solid #ddd;
  /* Adjust the padding */
  text-align: center;
}

.header-cell {
  background-color: #f2f2f2;
}

.data-cell {
  /* padding: auto; */
  /* Adjust the padding */
  /* text-align: center; */
  cursor: pointer;
  /* Adjust the font size */
  /* height: 20px; */
  /* Adjust the height */
  /* width: 20px; */
  /* Adjust the width */
  user-select: none;
  /* Prevent text selection */
}
